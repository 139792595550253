import ReactDOM from "react-dom/client";

import { Root } from "./Root";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./index.scss";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Root />,
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    (async () => {
      if (registration?.waiting) {
        await registration.unregister();
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.document.addEventListener("visibilitychange", () => {
          if (window.document.visibilityState === "visible") {
            window.location.reload();
          }
        });
        window.location.reload();
      }
    })();
  },
});

reportWebVitals();
