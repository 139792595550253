import "./App.scss";
import Image from "./AppImage.jpg";
import AppStoreIcon from "./AppStoreIcon.png";
import GooglePlayIcon from "./GooglePlayIcon.png";

const classNamePrefix = "App";

export const App = () => {
  document.getElementById("splash-screen")?.remove();
  return (
    <div className={classNamePrefix}>
      <header className={`${classNamePrefix}-header`}>
        <img alt="" height="52" src="/app.svg" width="180" />
      </header>
      <main className={`${classNamePrefix}-main`}>
        <div className={`${classNamePrefix}-routes`}>
          <p>
            Votre espace privé client évolue. Vous pouvez désormais accéder à
            votre nouvel espace client sur le site{" "}
            <a
              href="https://mon-espace-immo-grands-comptes.fr/"
              rel="noreferrer"
              target="_blank"
            >
              Mon espace immo : mon espace client grands comptes
            </a>{" "}
            ou en téléchargeant la nouvelle app mon-espace-immo-grands-comptes
            depuis le store.{" "}
            <strong>
              En pratique, c’est le seul changement pour vous. Vos
              interlocuteurs et la gestion de vos biens restent les mêmes.
            </strong>
          </p>
          <p>
            Pour des questions de sécurité, lors de votre première connexion,
            nous vous invitons à réinitialiser votre mot de passe en cliquant
            sur « mot de passe oublié » suite à quoi vous recevrez un email pour
            définir un nouveau mot de passe. En cas de difficulté, consultez
            notre FAQ{" "}
            <a
              href="https://www.lamy-immobilier.fr/faq"
              rel="noreferrer"
              target="_blank"
            >
              Lamy répond à vous questions concernant votre logement
            </a>
            .
          </p>
          <nav
            aria-label="Disponible sur Android et iOS"
            className={`${classNamePrefix}-stores`}
          >
            <strong>Télécharger l’app</strong>
            <ul>
              <li>
                <a
                  aria-label="Disponible sur Apple Store - Nouvelle fenêtre"
                  href="https://apps.apple.com/us/app/mon-espace-immo-grands-compte/id6739296806"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img alt="" height="53" src={AppStoreIcon} width="150" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Disponible sur Google Play - Nouvelle fenêtre"
                  href="https://play.google.com/store/apps/details?id=fr.mon_espace_immo_grands_comptes.www.twa&pli=1"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img alt="" height="53" src={GooglePlayIcon} width="150" />
                </a>
              </li>
            </ul>
          </nav>
          <img alt="" height="84" src="/company.svg" width="124" />
        </div>
        <div className={`${classNamePrefix}-ad`}>
          <div>
            <div>
              <img alt="" src={Image} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
