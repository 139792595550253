import React from "react";

import { App } from "./App";

export const Root = () => (
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);
